<template>
    <div>
        <b-card-code >
          <b-row>
            <b-col md="6" xl="6" class="mb-1">
              <b-form-group label="Nombre del Proyecto" label-for="basicInput">
                <b-form-input v-model="investment.projectName" disabled/>
              </b-form-group>
            </b-col>                
            <b-col md="6" xl="6" class="mb-1">
              <b-form-group label="Código de Proyecto" label-for="basicInput">
                <b-form-input v-model="investment.projectCode" disabled/>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="3" xl="3" class="mb-1">
              <b-form-group label="Nombre de Neoganadero" label-for="basicInput">
                <b-form-input v-model="investment.investorName" disabled/>
              </b-form-group>
            </b-col>                
            <b-col md="3" xl="3" class="mb-1">
              <b-form-group label="Email Neoganadero" label-for="basicInput">
                <b-form-input v-model="investment.investorEmail" disabled/>
              </b-form-group>
            </b-col>
            <b-col md="3" xl="3" class="mb-1">
              <b-form-group label="Telefono Neoganadero" label-for="basicInput">
                <b-form-input v-model="investment.investorTelephone" disabled/>
              </b-form-group>
            </b-col>
              <b-col md="3" xl="3" class="mb-1">
                <b-form-group label="Score Check Truora" label-for="basicInput">
                  <b-form-input v-model="investment.truoraCheckScore" disabled/>
                </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6" xl="6" class="mb-2">
              <b-form-checkbox v-model="isConfirmed"  name="check-button" switch inline> La inversión esta confirmada? </b-form-checkbox>
            </b-col> 
          </b-row>

          <b-row>
            <b-col md="4" xl="4" class="mb-1">
              <b-form-group label="Cantidad de Dinero invertido" label-for="basicInput">                
                <cleave id="number5" v-model.number="investment.amountInvested" class="form-control" :options="options.number"/>               
              </b-form-group>
            </b-col>                
            <b-col md="4" xl="4" class="mb-1" v-if="investment.projectType == 'Levante' || investment.projectType == 'Ceba'">
              <b-form-group label="Cantidad de Kilos comprados" label-for="basicInput">                
                <cleave id="number6" v-model.number="investment.totalKilograms" class="form-control" :options="options.number"/>               
              </b-form-group>
            </b-col>
            <b-col md="4" xl="4" class="mb-1" v-if="investment.projectType == 'Cria'">
              <b-form-group label="Cantidad de Sostycs comprados" label-for="basicInput">                
                <cleave id="number6" v-model.number="investment.totalUnits" class="form-control" :options="options.number"/>               
              </b-form-group>
            </b-col>
            <b-col md="4" xl="4" class="mb-1">
              <b-form-group label="Valor Recibido" label-for="basicInput">                
                <cleave id="number6" v-model.number="investment.amountReceived" class="form-control" :options="options.number"/>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group label-for="basicInput">
                <p>Adjuntar/modificar y descargar el comprobante de pago
                  <b-card-text style="color:#00BD56" @click="openTemplateDocument('paymentProofFile')" class="font-small-3 mb-0 sosty-pointer primary">
                    (Descargar)
                  </b-card-text>
                </p>
                <b-form-file placeholder="Seleccione un archivo" v-model="paymentProofFile" />                
                <b-card-text @click="openDocument(investment.paymentProofUrl)" accept="image/*" v-if="investment.paymentProofUrl" class="cursor-pointer sosty-font-smaller">abrir <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
              </b-form-group>
            </b-col>            
            <b-col>
              <b-form-group label-for="basicInput">
                <p>Descargar el PDF truora
                  <b-card-text style="color:#00BD56" @click="openTemplateDocument('truoraPdf')" class="font-small-3 mb-0 sosty-pointer primary">
                    (Descargar)
                  </b-card-text>
                </p>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-code >
        
        <b-card-code>
            <b-row>
                <b-col v-if="!processing" md="3" lg="3" offset-lg="9" offset-xl="9">
                    <b-button variant="primary" block @click="saveInvestmentDetails" >Guardar</b-button>
                </b-col>
                <b-col v-if="processing" md="3" lg="3" offset-lg="9" offset-xl="9">
                    <b-button variant="primary" disabled block></b-button>
                </b-col>
            </b-row>                   
        </b-card-code>
    </div>
  
</template>

<script>
import axios from '@axios'
import Cleave from 'vue-cleave-component'
import BCardCode from '@core/components/b-card-code'
import {
  BTabs, BTab, BFormInput, BRow, BCol, BFormGroup, BFormDatepicker, BFormTextarea, BFormFile, BButton, BSpinner, BFormSelect, BCardText, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BCardCode,
    BTabs, 
    BTab,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormDatepicker,
    BFormTextarea,   
    BFormFile,
    BButton,
    BSpinner,
    BFormSelect,
    BCardText,
    BFormCheckbox,
    Cleave,
  },
  data() {
    return {
      options: {
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      processing: false,
      investmentID: null,
      investment: {},
      projectName: null,
      paymentProofFile: null,
      paymentProofUrl: null,
      contratoColaboracionFile: null,
      contratoColaboracionUrl: null,
      contratoMandatoFile: null,
      contratoMandatoUrl: null,
      truoraPdf: null,
      isConfirmed: null,      
      InitialPricePerKilogram: 0,
    }
  },
  created() {    
    if(this.$route != null && this.$route.query != null && this.$route.query.investmendID != null) {
      this.investmentID = this.$route.query.investmendID;
      this.getInvestmentPublicById();
    } else {
      this.investmendID = null;
    }
  },
  watch:{
    projectName: function(val) { this.projectName = val; },  
    paymentProofFile: function(val) { this.paymentProofFile = val;  this.uploadFile(val, 'paymentProofFile'); },
    contratoColaboracionFile: function(val) { this.contratoColaboracionFile = val; this.uploadFile(val, 'contratoColaboracionFile'); }, 
    contratoMandatoFile: function(val) { this.contratoMandatoFile = val; this.uploadFile(val, 'contratoMandatoFile'); }, 
    truoraPdf: function(val) { this.truoraPdf = val; this.uploadFile(val, 'truoraPdf'); },
  },
  methods: {
    getInvestmentPublicById(){
      this.processing = true;  
      axios.get('/Investment/GetInvestmentPublicById', { params: { investmendID: this.investmentID}})
      .then(response => {
        this.processing = false;
        this.investment = response.data
        this.investment.amountInvested = parseFloat((this.investment.amountInvested.replace(',', '.')))
        if(!this.investment.amountReceived){
          this.investment.amountReceived = 0
        }
        this.InitialPricePerKilogram = this.investment.amountInvested/this.investment.totalKilograms;
        if(this.investment.isConfirmed == 'SI'){
          this.isConfirmed = true
        }else{
          this.isConfirmed = false
        }
      })
    },
    uploadFile(fileToUpload, fileType) {
      this.processing = true;               
      let formData = new FormData();
      formData.append('file', fileToUpload);
      axios.post('/File/UploadFile', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(response => {
        this.processing = false;
        if(response.data) {
          this.setFileUrl(fileType, response.data);
          this.$bvToast.toast("Documento subido de forma correcta", { title: 'Ok', variant: 'success', solid: true, });
        } else {
          this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
        }
      })
      .catch(error => {
        this.processing = false;
          if(error.response){
            this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
          } else {
            this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
          }
      })
    },
    setFileUrl(fileType, fileUrl) {
      switch(fileType) {
          case 'paymentProofFile':
            this.investment.paymentProofUrl = fileUrl;
            break;
          case 'contratoColaboracionFile':
            this.investment.contratoColaboracionUrl = fileUrl;
            break;
          case 'contratoMandatoFile':
            this.investment.contratoMandatoUrl = fileUrl;
            break;
          case 'truoraPdf':
            this.investment.truoraPdf = fileUrl;
            break;
      }
    },
    openDocument(fileUrl) {
        window.open(fileUrl, '_blank');
    },
    openTemplateDocument(fileType) {
        var fileUrl = '';
        switch(fileType) {  
          case 'paymentProofFile':
            fileUrl = this.investment.paymentProofUrl;
            break;
          case 'contratoColaboracionFile':
            fileUrl = this.investment.contratoColaboracionUrl;
            break;
          case 'contratoMandatoFile':
            fileUrl = this.investment.contratoMandatoUrl;
            break;
          case 'truoraPdf':
            fileUrl = this.investment.truoraPdf;
            break;
            
        }
        window.open(fileUrl, '_blank');
    },
    saveInvestmentDetails() {
      this.processing = true; 
      this.investment.isConfirmed = this.isConfirmed          
      var payload = {      
        investment: this.investment,        
      }
      var isNew = this.investment.investmentID == null || this.investment.investmentID == undefined;
      axios.post('/Investment/SaveInvestment', payload)
      .then(response => {
        this.processing = false;        
        if(response.data){
          this.$bvToast.toast("Información guardada exitosamente", { title: 'Ok', variant: 'success', solid: true, })          
        }else{
          this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })
        }        
      })
      .catch(error => {
        this.processing = false;
        if(error.response){
          this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
        } else {
          this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
        }
      })
    }
  },  
}
</script>